/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

export { wrapRootElement } from "./src/apollo/wrapRootElement"



export const onClientEntry = () => {

  // remove prelaod class from body (prevents jumpy animations)
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(
      /\bpreload\b/,
      ""
    )
  })

  // usercentric handling
  window.addEventListener("load", () => {

    const initGTM = () => {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.async = true
      script.onload = () => {
        // check if dataLayer exists
        if (typeof window.dataLayer !== "undefined") {
          window.dataLayer.push({ event: "gtm.js", "gtm.start": new Date().getTime(), "gtm.uniqueEventId": 0 })
        }
      }
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-MNKJ6FZ"
      document.head.appendChild(script)
    }
    const checkallServices = () => {
      // check if UC_UI exists
      if (typeof window.UC_UI !== "undefined") {
        let services = window.UC_UI.getServicesBaseInfo()
        // get all services whose categorySlug is either 'marketing' or 'functional'
        let filteredServices = services.filter(service => service.categorySlug === "marketing" || service.categorySlug === "functional")
        // check if one of the filtered services is accepted
        let hasConsent = filteredServices.some(service => service.consent.status === true)
        if (hasConsent) {
          localStorage.setItem("GtmLoadable", "true")
        } else {
          localStorage.removeItem("GtmLoadable")
        }
        if (localStorage.getItem("GtmLoadable") === "true") {
          initGTM()
        }
      }

    }
    window.addEventListener("UC_UI_INITIALIZED", ev => {
      checkallServices()
      window.addEventListener("UC_UI_VIEW_CHANGED", ev => {
        checkallServices()
      })
      // add event listener for all a tags with #uc-corner-modal-show href attribute
      if (typeof window.UC_UI !== "undefined") {
        document.querySelectorAll("a[href=\"#uc-corner-modal-show\"]").forEach(el => {
          el.addEventListener("click", ev => {
            ev.preventDefault()
            window.UC_UI.showFirstLayer()
          })
        })
      }
    })
  })

}

// Don't scroll to top when the search parameters change
// See https://github.com/alexluong/gatsby-packages/issues/33#issuecomment-660541167
export const shouldUpdateScroll = ({
                                     routerProps: { location },
                                     getSavedScrollPosition
                                   }) => {
  // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
  const paramNames = ["filters", "sortBy", "search", "page", "days", "from", "until", "adults", "childrenAges", "skipEmpty", "showHidden", "priceFrom", "priceTo"]
  for (let i = 0; i < paramNames.length; i++) {
    if (location.search.includes(`${paramNames[i]}=`)) {
      return false
    }
  }
  const currentPosition = getSavedScrollPosition(location)
  return currentPosition || [0, 0]
}
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import SimpleReactLightbox from 'simple-react-lightbox';
import { GatsbyAdapter } from "../components/GatsbyAdapter"
import { QueryParamProvider } from "use-query-params"

export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>
    <ApolloProvider client={client}>
      <QueryParamProvider adapter={GatsbyAdapter} options={{
        enableBatching: true,

      }}>
        {element}
      </QueryParamProvider>
    </ApolloProvider>
  </SimpleReactLightbox>
)
